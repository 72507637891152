/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"

import CTABlock from "../components/common/CTABlock"
import Layout from "../components/common/Layout"
import PlanSteps from "../components/common/PlanSteps"
import ProblemPoints from "../components/common/ProblemPoints"
import SectionHeader from "../components/common/SectionHeader"
import SEO from "../components/common/SEO"

import CTAMain from "../components/content/CTAMain"
import LandingHeader from "../components/content/LandingHeader"
import TestimonialsBlock from "../components/content/TestimonialsBlock"
import PressBlock from "../components/content/PressBlock"

const LandingPage = ({ data: { prismicLandingPage } }) => {
  const { data } = prismicLandingPage

  return (
    <Layout>
      <SEO
        title={data.title.text}
        description={data.meta_description.text}
        canonicalUrl={data.canonical_url.text}
      />
      <LandingHeader
        heading={data.hero_heading.text}
        introduction={data.hero_text.text}
        CTAButtonLabel={data.header_cta_button_label.text}
        CTASubText={data.header_cta_sub_text.text}
      />

      <div>
        <SectionHeader>{data.problem_heading.text}</SectionHeader>
        <ProblemPoints
          points={data.pain_points.map(({ heading, text, image }) => ({
            heading: heading.text,
            text: text.text,
            image: image.url,
          }))}
        />
      </div>

      <CTABlock
        heading={data.guide_heading.text}
        CTAButtonLabel={data.guide_cta_button_label.text}
        CTASubText={data.guide_cta_sub_text.text}
      />

      <div>
        <SectionHeader>{data.plan_heading.text}</SectionHeader>
        <PlanSteps
          steps={data.plan_steps.map(
            ({
              heading,
              description,
              sign_up_label,
              link,
              link_label,
              image,
            }) => ({
              heading: heading.text,
              description: description.html,
              image: image.url,
              ctaLabel: sign_up_label.text,
              linkLabel: link_label.text,
              linkTo: link.url,
            })
          )}
        />
      </div>

      <TestimonialsBlock
        heading={data.testimonials_heading.text}
        testimonials={data.testimonials.map(
          ({ name, label, image, quote }) => ({
            name: name.text,
            label: label.text,
            image: image.url,
            quote: quote.html,
          })
        )}
        CTAButtonLabel={data.testimonials_cta_button_label.text}
        CTASubText={data.testimonials_cta_sub_text.text}
      />

      <PressBlock
        heading={data.press_heading.text}
        links={data.press_links.map(
          ({
            press_link_heading,
            press_link_quote,
            press_link_label,
            press_link_url,
            press_link_image,
          }) => ({
            heading: press_link_heading.text,
            quote: press_link_quote.html,
            label: press_link_label.text,
            url: press_link_url.url,
            image: press_link_image.url,
          })
        )}
        CTAButtonLabel={data.testimonials_cta_button_label.text}
        CTASubText={data.testimonials_cta_sub_text.text}
      />

      <CTAMain
        heading={data.cta_heading.text}
        text={data.cta_text.text}
        buttonLabel={data.cta_button_label.text}
        subText={data.cta_sub_text.text}
      />
    </Layout>
  )
}

export default LandingPage

export const pageQuery = graphql`
  query LandingPageQuery {
    prismicLandingPage {
      data {
        title {
          text
        }
        meta_description {
          text
        }
        canonical_url {
          text
        }
        hero_heading {
          text
        }
        hero_text {
          text
        }
        header_cta_button_label {
          text
        }
        header_cta_sub_text {
          text
        }
        problem_heading {
          text
        }
        pain_points {
          heading {
            text
          }
          text {
            text
          }
          image {
            url
          }
        }
        guide_heading {
          text
        }
        guide_cta_button_label {
          text
        }
        guide_cta_sub_text {
          text
        }
        plan_heading {
          text
        }
        plan_steps {
          heading {
            text
          }
          description {
            html
          }
          sign_up_label {
            text
          }
          link {
            url
          }
          link_label {
            text
          }
          image {
            url
          }
        }
        testimonials_heading {
          text
        }
        testimonials {
          name {
            text
          }
          label {
            text
          }
          quote {
            html
          }
          image {
            url
          }
        }
        testimonials_cta_button_label {
          text
        }
        testimonials_cta_sub_text {
          text
        }
        press_heading {
          text
        }
        press_links {
          press_link_heading {
            text
          }
          press_link_quote {
            html
          }
          press_link_label {
            text
          }
          press_link_url {
            url
          }
          press_link_image {
            url
          }
        }
        cta_heading {
          text
        }
        cta_text {
          text
        }
        cta_button_label {
          text
        }
        cta_sub_text {
          text
        }
      }
    }
  }
`
