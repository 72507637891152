import styled from "styled-components"
import Button from "./Button"

export default styled.div`
  /* display: flex; */

  ${Button} {
    width: 100%;
    display: block;

    margin: 0.5rem 0;

    @media ${({ theme }) => theme.device.tablet} {
      display: inline-block;
      margin: 0 0.25rem;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`
