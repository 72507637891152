import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import CTAButton from "./CTAButton"

const CTABlock = ({ className, heading, CTAButtonLabel, CTASubText }) => {
  return (
    <div className={className}>
      <h2>{heading}</h2>
      <CTAButton label={CTAButtonLabel} text={CTASubText} />
    </div>
  )
}

CTABlock.propTypes = {
  className: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  CTAButtonLabel: PropTypes.string,
  CTASubText: PropTypes.string,
}

CTABlock.defaultProps = {
  CTAButtonLabel: undefined,
  CTASubText: undefined,
}

export default styled(CTABlock)`
  background-color: ${({ theme }) => theme.colors["gray-100"]};
  padding: 2rem;

  @media ${({ theme }) => theme.device.mobileM} {
    padding: 4rem 3rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 6rem 2rem;
  }

  h2 {
    font-size: 3rem;
    font-weight: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors["blue-800"]};
    max-width: 700px;

    @media ${({ theme }) => theme.device.mobileM} {
      font-size: 3.5rem;
    }

    @media ${({ theme }) => theme.device.mobileL} {
      font-size: 4rem;
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: 5rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
`
