import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import CTAButton from "../common/CTAButton"
import RewardPartnerRoll from "./RewardPartnerRoll"
import Container from "../common/Container"

import heroImg from "../../images/illustrations/hero.svg"

const LandingHeader = ({
  className,
  heading,
  introduction,
  CTAButtonLabel,
  CTASubText,
}) => {
  return (
    <div className={className}>
      <Container>
        <h1>{heading}</h1>
        <p>{introduction}</p>
        <CTAButton label={CTAButtonLabel} text={CTASubText} />
        <img src={heroImg} alt="" />
        <RewardPartnerRoll />
      </Container>
    </div>
  )
}

LandingHeader.propTypes = {
  className: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  CTAButtonLabel: PropTypes.string,
  CTASubText: PropTypes.string,
}

LandingHeader.defaultProps = {
  CTAButtonLabel: undefined,
  CTASubText: undefined,
}

export default styled(LandingHeader)`
  padding: 3rem 2rem;
  background-color: ${({ theme }) => theme.colors["gray-200"]};

  @media ${({ theme }) => theme.device.mobileL} {
    background-color: ${({ theme }) => theme.colors["gray-100"]};
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 5rem 2rem 0;
  }

  h1 {
    font-size: 3.6rem;
    font-weight: normal;
    text-align: center;
    line-height: 0.9;

    @media ${({ theme }) => theme.device.mobileM} {
      font-size: 4.3rem;
    }

    @media ${({ theme }) => theme.device.mobileL} {
      font-size: 5rem;
    }

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 6rem;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: 6.5rem;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 1.2;
    text-align: center;
    margin: 2rem auto;
    color: ${({ theme }) => theme.colors["gray-700"]};

    @media ${({ theme }) => theme.device.mobileM} {
      font-size: 2.2rem;
    }

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 3rem;
      max-width: 700px;
    }
  }

  img {
    width: 100%;
    max-width: 1000px;
    display: block;
    margin: 3rem auto;
  }

  ${CTAButton} {
    margin-top: 4rem;
  }
`
