/* eslint-disable react/prop-types */
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import LogoRoll from "../common/LogoRoll"

const RewardPartnerRoll = ({ compact }) => (
  <StaticQuery
    query={graphql`
      query CustomerRollQuery {
        prismicLogoRoll(uid: { eq: "reward-partners" }) {
          data {
            heading {
              text
            }
            logos {
              name {
                text
              }
              image {
                url
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { heading, logos } = data.prismicLogoRoll.data
      return (
        <LogoRoll
          heading={heading.text}
          logos={logos.map(({ name, image }) => ({
            name: name.text,
            image: image.url,
          }))}
          compact={compact}
        />
      )
    }}
  />
)

export default RewardPartnerRoll
