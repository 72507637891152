/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const LogoRoll = ({ heading, logos, className, compact, ...props }) => (
  <div {...props} className={className}>
    {heading && <h5>{heading}</h5>}
    <div>
      {logos.map(({ name, image, link }) =>
        link ? (
          <a key={name} href={link}>
            <img src={image} alt={name} />
          </a>
        ) : (
          <img key={name} src={image} alt={name} />
        )
      )}
    </div>
  </div>
)

LogoRoll.defaultProps = {
  heading: undefined,
  compact: false,
}

LogoRoll.propTypes = {
  heading: PropTypes.string,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string,
      link: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string.isRequired,
  compact: PropTypes.bool,
}

export default styled(LogoRoll)`
  margin: 0;

  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ compact }) => (compact ? "3rem 0" : "10rem 0")};
  }

  h5 {
    text-align: center;
    font-weight: 400;
    text-transform: none;
    margin: 0 0 2rem;
    color: ${({ theme }) => theme.colors["blue-500"]};
  }

  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    img {
      width: 120px;
      height: 80px;
      object-fit: contain;
      margin: 1rem 2rem;

      @supports (mix-blend-mode: multiply) {
        mix-blend-mode: multiply;
      }
    }

    @media ${({ theme }) => theme.device.tablet} {
      img {
        height: 100px;
        object-fit: contain;
        margin: 1rem 2rem;
      }

      img:first-child {
        margin-left: 0;
      }

      img:last-child {
        margin-right: 0;
      }
    }
  }
`
