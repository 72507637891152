import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// import CTAButton from "../common/CTAButton"

const TestimonialBlock = ({
  className,
  heading,
  links,
  // CTAButtonLabel,
  // CTASubText,
}) => {
  return (
    <div className={className}>
      <h2>{heading}</h2>
      <div className="press-blocks">
        {links.map(link => (
          <div className="press-block">
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              <img src={link.image} alt={link.heading} />
            </a>
            <div dangerouslySetInnerHTML={{ __html: link.quote }} />
            <a
              className="press-text-link"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.label}
            </a>
          </div>
        ))}
      </div>

      {/* <CTAButton label={CTAButtonLabel} text={CTASubText} /> */}
    </div>
  )
}

TestimonialBlock.propTypes = {
  className: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      quote: PropTypes.string,
      image: PropTypes.string,
      url: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  // CTAButtonLabel: PropTypes.string.isRequired,
  // CTASubText: PropTypes.string.isRequired,
}

export default styled(TestimonialBlock)`
  background-color: ${({ theme }) => theme.colors["gray-100"]};
  padding: 2rem 2rem 4rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 4rem 2rem;
  }

  h2 {
    font-size: 2.6rem;
    font-weight: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors["blue-700"]};

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 4rem;
      max-width: 600px;
      margin: 2rem auto;
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: 5rem;
      max-width: 700px;
    }
  }

  .press-blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .press-block {
    padding: 2rem;
    flex: 0 300px;
    margin-bottom: 2rem;

    img {
      height: 150px;
      width: 100%;
      max-width: 400px;
      object-fit: contain;
    }

    p {
      margin: 0;
      text-align: center;
      display: block;
      font-size: 1.5rem;
    }

    .press-text-link {
      margin-top: 2rem;
      text-align: center;
      width: 100%;
      font-size: 1.5rem;
      display: block;
      text-decoration: underline;
    }
  }
`
