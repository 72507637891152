import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Testimonial from "../common/Testimonial"
// import CTAButton from "../common/CTAButton"

const TestimonialBlock = ({
  className,
  heading,
  testimonials,
  // CTAButtonLabel,
  // CTASubText,
}) => {
  return (
    <div className={className}>
      <h2>{heading}</h2>
      {testimonials.map(({ name, label, image, quote }) => (
        <Testimonial name={name} label={label} image={image}>
          <div dangerouslySetInnerHTML={{ __html: quote }} />
        </Testimonial>
      ))}
      {/* <CTAButton label={CTAButtonLabel} text={CTASubText} /> */}
    </div>
  )
}

TestimonialBlock.propTypes = {
  className: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  // CTAButtonLabel: PropTypes.string.isRequired,
  // CTASubText: PropTypes.string.isRequired,
}

export default styled(TestimonialBlock)`
  background-color: ${({ theme }) => theme.colors["gray-100"]};
  padding: 2rem 2rem 4rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 4rem 2rem;
  }

  h2 {
    font-size: 2.6rem;
    font-weight: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors["blue-700"]};

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 4rem;
      max-width: 600px;
      margin: 2rem auto;
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: 5rem;
      max-width: 700px;
    }
  }
`
