import styled from "styled-components"

export default styled.h3`
  font-size: 2.4rem;
  line-height: 1.1;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: normal;
  color: ${({ theme }) => theme.colors["blue-800"]};
  margin: 4rem auto;
  max-width: 350px;
  padding: 0 2rem;

  @media ${({ theme }) => theme.device.mobileM} {
    font-size: 3rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 3.2rem;
    max-width: 500px;
  }

  @media ${({ theme }) => theme.device.laptop} {
    font-size: 3.4rem;
  }
`
