import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Button from "./Button"
import ButtonGroup from "./ButtonGroup"

const PlanSteps = ({ className, steps }) => {
  return (
    <div className={className}>
      {steps.map(
        ({ heading, description, image, ctaLabel, linkLabel, linkTo }) => (
          <div className="step" key={heading}>
            <img src={image} alt="" />
            <div className="content">
              <h4>{heading}</h4>
              <div dangerouslySetInnerHTML={{ __html: description }} />
              <ButtonGroup>
                <Button
                  label={ctaLabel || "Sign up"}
                  mode="cta"
                  linkTo="https://app.acceleratemymortgage.com/signup"
                />
                {linkTo && (
                  <Button
                    label={linkLabel || "Find out more"}
                    linkTo={linkTo}
                  />
                )}
              </ButtonGroup>
            </div>
          </div>
        )
      )}
    </div>
  )
}

PlanSteps.propTypes = {
  className: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      label: PropTypes.string,
      points: PropTypes.arrayOf(PropTypes.string).isRequired,
      image: PropTypes.string.isRequired,
      linkTo: PropTypes.string,
    })
  ).isRequired,
}

export default styled(PlanSteps)`
  .step {
    padding: 0 2rem;
    margin: 6rem auto 8rem;

    @media ${({ theme }) => theme.device.tablet} {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin: 10rem auto;

      ${Button} {
        display: inline-block;
        width: auto;
      }
    }

    img {
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
      display: block;

      @media ${({ theme }) => theme.device.mobileL} {
        max-width: 250px;
      }

      @media ${({ theme }) => theme.device.tablet} {
        max-width: 300px;
        margin: 3rem 3rem 0 0;
      }

      @media ${({ theme }) => theme.device.laptop} {
        max-width: 350px;
        margin: 3rem 6rem 0 0;
      }
    }

    .content {
      @media ${({ theme }) => theme.device.tablet} {
        width: 400px;
        margin-left: 3rem;
      }

      @media ${({ theme }) => theme.device.laptop} {
        width: 480px;
        margin-left: 1rem;
      }
    }

    h4 {
      font-size: 3rem;
      margin: 2rem 0;

      @media ${({ theme }) => theme.device.mobileL} {
        font-size: 3.2rem;
      }

      @media ${({ theme }) => theme.device.tablet} {
        font-size: 3.8rem;
      }
    }

    ul {
      margin: 1rem 0 3rem;
      padding-left: 2rem;

      li {
        font-size: 1.8rem;
        line-height: 1.8;
        color: ${({ theme }) => theme.colors["gray-700"]};

        @media ${({ theme }) => theme.device.mobileL} {
          font-size: 2rem;
        }

        @media ${({ theme }) => theme.device.tablet} {
          font-size: 2.2rem;
        }
      }
    }
  }
`
