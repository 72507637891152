import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Testimonial = ({ className, children, image, name, label }) => {
  return (
    <blockquote className={className}>
      <main>{children}</main>
      <footer>
        <img src={image} alt="" />
        <h5>{name}</h5>
        <span>{label}</span>
      </footer>
    </blockquote>
  )
}

Testimonial.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default styled(Testimonial)`
  padding: 0rem;
  margin: 3rem 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
    margin: 8rem 0;

    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }

  main {
    /* border-bottom: 10px solid ${({ theme }) => theme.colors["gray-300"]}; */
    /* padding-bottom: 2rem; */

    @media ${({ theme }) => theme.device.tablet} {
      /* border-left: 10px solid ${({ theme }) => theme.colors["gray-300"]}; */
      /* padding-left: 2rem; */
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  p {
    font-size: 2rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors["gray-700"]};
    font-family: "hk_groteskitalic";

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 2.4rem;
      max-width: 500px;
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: 2.6rem;
      max-width: 500px;
    }
  }

  footer {
    text-align: center;
    margin-top: 1rem;

    @media ${({ theme }) => theme.device.tablet} {
      width: 300px;
      padding: 0 4rem;
    }

    img {
      border-radius: 100%;
      width: 100%;
      max-width: 150px;
      display: block;
      margin: 1rem auto;

      @media ${({ theme }) => theme.device.tablet} {
        width: 200px;
        max-width: 200px;
      }
    }

    h5 {
      font-size: 2rem;
      margin: 2rem 0 1rem;
      color: ${({ theme }) => theme.colors["blue-900"]};
    }

    span {
      font-size: 1.6rem;
      margin: 1rem 0;
      color: ${({ theme }) => theme.colors["gray-500"]};

      @media ${({ theme }) => theme.device.tablet} {
        font-size: 1.8rem;
      }
    }
  }
`
