import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Container from "./Container"

const ProblemPoints = ({ className, points }) => {
  return (
    <Container wide>
      <div className={className}>
        {points.map(({ heading, text, image }) => (
          <div key={heading}>
            <img src={image} alt="" />
            <h4>{heading}</h4>
            <p>{text}</p>
          </div>
        ))}
      </div>
    </Container>
  )
}

ProblemPoints.propTypes = {
  className: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.string,
    })
  ).isRequired,
}

export default styled(ProblemPoints)`
  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    justify-content: center;
    margin-bottom: 8rem;
  }

  div {
    padding: 0 1rem;
    margin: 6rem 0;

    @media ${({ theme }) => theme.device.tablet} {
      margin: 0 1rem;
    }

    @media ${({ theme }) => theme.device.laptop} {
      margin: 0 2rem;
    }

    &:first-child {
      margin-top: 0px;
    }

    img {
      height: 150px;
      margin: 0 auto;
      display: block;
    }

    h4 {
      font-size: 2.4rem;
      text-align: center;
      font-weight: normal;
      margin: 2rem 0;
      font-family: ${({ theme }) => theme.subHeadingFontFamily};

      @media ${({ theme }) => theme.device.mobileL} {
        font-size: 2.6rem;
      }

      @media ${({ theme }) => theme.device.tablet} {
        font-size: 2.1rem;
      }

      @media ${({ theme }) => theme.device.laptop} {
        font-size: 2.6rem;
      }
    }

    p {
      font-size: 1.8rem;
      line-height: 1.1;
      text-align: center;
      color: ${({ theme }) => theme.colors["gray-700"]};

      @media ${({ theme }) => theme.device.mobileM} {
        font-size: 2rem;
      }

      @media ${({ theme }) => theme.device.mobileL} {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`
